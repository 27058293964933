/* eslint-disable no-unneeded-ternary */
<template>
  <section class="intro-x my-5">
    <div class="w-full flex items-center mb-5">
      <back-button class="mr-2" />
      <h1 class="text-lg font-bold">Entradas de inventario</h1>
    </div>
    <Form>
      <div class="w-full grid grid-cols-1 md:grid-cols-3 gap-4 mb-2">
        <!-- <div class="mb-2">
          <div>Fecha de entrada</div>
          <div>
            <Field
            v-model="model.date"
            type="date"
            class="form-control"
            name="date"
            />
            <ErrorMessage name="date" class="text-red-600" />
          </div>
        </div> -->
        <!-- branches office -->
        <!-- <div class="mb-1">
          <label for="fecha">Ubicación despacho</label>
          <select name="branchOfficeId"
          v-model="model.branchOfficeId"
          class="form-control capitalize"
          >
            <option :value="null">Seleccionar</option>
            <option
            v-for="(row,k) in optiosBranchOffice"
            :key="k"
            :value="row.id"
            class="capitalize"
            >
              {{row.name}}
            </option>
          </select>
          <messageError :text="errors.branchOfficeId" />
        </div> -->
      </div>
    </Form>
    <div class="w-full">
      <h2 class="text-lg font-bold">Artículos</h2>
    </div>
    <div class="w-full mb-3">
      <DataTable :value="getArticulos">
        <Column headerStyle="width:50px;">
          <template #body="{ data }">
              <div>
                <button
                @click="onRemoveArticle(data)"
                type="button"
                class="bg-red-700 text-white rounded-md btn-remover py-1 px-1"
                >
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                  </svg>
                </button>
              </div>
          </template>
        </Column>
        <Column header="NOMBRE" field="name">
          <template #body="{ data }">
            <div class="p-inputgroup">
              <AutoComplete
                v-model="data.detail"
                :suggestions="filteredProducts"
                @complete="searchProduct($event)"
                @item-select="selectProduct($event,data._key)"
                field="codeName"
                inputClass="form-control"
              >
                <template #item="{ item }">
                  <div>
                    <span>{{item.codeName}}</span>
                  </div>
                </template>
              </AutoComplete>
            </div>
            <messageError :text="errors[`articulos[${data._key}].detail`]" />
          </template>
        </Column>
        <!-- <Column header="PRESENTACIÓN">
          <template #body="{ data }">
            <div class="p-inputgroup">
              <select name="presentacion"
              v-model="data.presentation_id"
              class="form-control capitalize"
              >
                <option :value="null">Seleccionar</option>
                <option
                v-for="(row,k) in data.presentations"
                :key="k"
                :value="row.id"
                class="capitalize"
                >
                  {{row.presentation.name}}
                </option>
              </select>
            </div>
            <messageError :text="errors[`articulos[${data._key}].presentation_id`]" />
          </template>
        </Column> -->
        <Column header="CANTIDAD">
          <template #body="{ data }">
            <div class="p-inputgroup">
              <input @keyup="data.stock=$h.onlyNumber($event.target.value)"
              type="number"
              class="form-control"
              v-model="data.stock">
            </div>
            <messageError :text="errors[`articulos[${data._key}].stock`]" />
          </template>
        </Column>
      </DataTable>
      <messageError :text="errors.articulos" />
      <small class="p-error">{{errorDuplicate}}</small>
    </div>
    <div class="flex flex-col md:flex-row">
      <button
      type="button"
      class="bg-primary-1 px-3 py-3 text-white rounded-md capitalize md:mr-2 "
      @click="onArticleAdd"
      >
        Agregar articulo
      </button>
      <button
      type="button"
      class="bg-green-600 px-3 py-3 text-white rounded-md capitalize"
      @click="onSubmit"
      >
        Recibir entrada
      </button>
    </div>
  </section>
</template>

<script>
// import listArticleInventary from '../../../../services/listArticleInventary'
import brancheOfficesListar from '../../../../services/brancheOfficesListar'
import findNameArticle from '../../../../services/findNameArticle'
import createEntryInventary from '../../../../services/inventaryEntryService'
import { computed, onMounted, ref } from 'vue'
import { object, array, number, string } from 'yup'
import { Form, useField, useForm } from 'vee-validate'
// import { object, array, number, string, date } from 'yup'
// import { Form, Field, ErrorMessage, useField, useForm } from 'vee-validate'
import { messageConfirm, messageWarning } from '../../../../../../../libs/mensajes'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import AutoComplete from 'primevue/autocomplete'
import dayjs from 'dayjs'
import { useRouter } from 'vue-router'

export default {
  components: {
    DataTable,
    Column,
    Form,
    // Field,
    // ErrorMessage,
    AutoComplete

  },
  name: 'EntradaInventario',
  setup () {
    /*
    * Refs and methods
    */
    const router = useRouter()
    const optiosBranchOffice = ref([])
    const filteredProducts = ref([])
    const autocompleteOpen = ref(false)
    const isAutocompleteOpen = computed(() => autocompleteOpen.value)
    const errorDuplicate = ref('')

    const getArticulos = computed(() => {
      return model.articulos
    })

    const validationSchema = object().shape({
      // date: date().required('requerido').label('fecha'),
      // branchOfficeId: number().required().nullable().label('ubicación despacho'),
      // invoice: string().required('requerido').label('factura'),
      articulos: array()
      .of(
        object().shape({
          // presentation_id: number().nullable(true).required().label('presentación del producto'),
          stock: number().nullable(true).min(1).required().label('cantidad del producto'),
          detail: object().shape({
            name: string().required().nullable().label('nombre del producto')
          }).required().nullable().label('detalles del producto')
        })
      )
      .min(1).label('articulos')
    })
    const { values: model, handleSubmit, handleReset, errors } = useForm({
      validationSchema
    })
    // useField('branchOfficeId', null, { initialValue: null })
    useField('articulos', null, {
      initialValue: [{ id: null, article_id: null, detail: null, _key: 0 }]
    })

    /*
    * Methods
    */

   const vaidateDuplicateArticle = () => {
      var vali = 0
      for (const v of model.articulos) {
        const itemVali = model.articulos.filter(f => f.article_id === v.article_id && f.presentation_id === v.presentation_id)
        if (itemVali.length > 1) {
          vali = vali + 1
        }
      }
      errorDuplicate.value = ''
      if (vali) {
        errorDuplicate.value = 'Existen artículos con su presentación duplicados'
        return false
      }
      return true
    }

    const onSubmit = handleSubmit(async (values) => {
      if (!vaidateDuplicateArticle()) return false
      messageConfirm().then(({ isConfirmed }) => {
        if (isConfirmed) {
          createEntryInventary(model).then(({ status, data }) => {
            if (status === 201) {
              messageConfirm('Desea crear otra entrada', 'Entrada creada', 'success').then(({ isConfirmed }) => {
                if (isConfirmed) {
                  errorDuplicate.value = ''
                  setTimeout(() => {
                    handleReset()
                    model.articulos = [{ id: null, article_id: null, detail: null, _key: 0 }]
                  }, 200)
                  return false
                }
                router.back()
              })
            }
          }).catch(err => {
            console.error(err.message)
            messageWarning(err.response.data.message)
          })
        }
      })
    })
    const removeDetail = (_key) => {
      model.articulos.splice(_key, 1)
      vaidateDuplicateArticle()
    }
    const onRemoveArticle = (data) => {
      removeDetail(data._key)
      if (data.id) {
        // messageSuccess('Artículo eliminado')
        // requestsDetailDelete({ id: data.id }).then(res => {
        //   console.log(res)
        //   messageSuccess('Artículo eliminado')
        // }).catch(err => {
        //   console.error(err.message)
        // })
      } else {
        // messageSuccess('Artículo eliminado')
      }
    }
    const onArticleAdd = () => {
      model.articulos.push({ id: null, detail: null, _key: model.articulos.length })
    }
    const searchProduct = ({ query }) => {
      if (!query.trim() || query.length < 3) {
        filteredProducts.value = []
      } else {
        return findNameArticle({ name: query.trim() }).then(({ data }) => {
          filteredProducts.value = data
        }).catch(err => {
          console.error(err.message)
        })
      }
    }
    const selectProduct = ({ value }, _key) => {
      model.articulos[_key].presentation_id = value.presentations.id
      model.articulos[_key].presentations = value.presentations
      model.articulos[_key].article_id = value.id
    }

    const branchOfficesFetch = () => {
      return brancheOfficesListar().then(({ data }) => {
        optiosBranchOffice.value = data
        return data
      }).catch(err => {
        console.error(err.message)
      })
    }

    /*
    * Hooks
    */
    onMounted(() => {
      branchOfficesFetch()
      setTimeout(() => {
          handleReset()
          model.date = dayjs().format('YYYY-MM-DD')
        }, 200)
    })

    return {
      model,
      errors,
      errorDuplicate,
      branchOfficesFetch,
      optiosBranchOffice,
      filteredProducts,
      isAutocompleteOpen,
      getArticulos,
      selectProduct,
      onArticleAdd,
      searchProduct,
      onSubmit,
      onRemoveArticle
    }
  }
}
</script>

<style>
/* table.dataTable.compact thead th {
  background: rgb(28, 63, 170) !important;
  color: white;
  border-right: 1px solid white;
} */
</style>
